<template>
  <div class="team-find">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-s">Пригласить участников</h2>
      <p
        v-if="isHighSchool"
        class="mb-m">
        Напоминаем, что вы&nbsp;можете пригласить в&nbsp;команду только
        участников того&nbsp;же трека, что и&nbsp;вы. Ваш трек:
        <span class="text-bold">{{ participantTrack }}</span>
      </p>
      <p class="mb-l">Есть два способа найти участников в команду:</p>
      <div>
        <section class="mb-l">
          <h4 class="text-size-h4 mb-s text-bold">
            1. Пригласите друзей, с&nbsp;которыми договорились
          </h4>
          <p class="mb-s">
            Если уже знаете, с&nbsp;кем будете в&nbsp;команде, скопируйте эту
            ссылку и&nbsp;отправьте&nbsp;им.
          </p>
          <CopyInput :value="inviteLink" />
        </section>
        <section class="mb-l">
          <h4 class="text-size-h4 mb-s text-bold">
            2. Найдите сокомандников в&nbsp;чате профиля
          </h4>
          <div class="typography">
            <p>
              Вступите в&nbsp;чат или группу профиля по&nbsp;ссылке
              и&nbsp;напишите объявление о&nbsp;том, что ищете участников
              в&nbsp;команду. Когда договоритесь, скопируйте ссылку выше
              и&nbsp;отправьте&nbsp;им.
            </p>
            <ul v-if="chatLinks && chatLinks.length">
              <li
                v-for="item in chatLinks"
                :key="item.id">
                <a
                  :href="item.url"
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ item.title }}&nbsp;<BaseIcon glyph="external-link"
                /></a>
              </li>
            </ul>
            <div v-else>
              <p>
                Пока у&nbsp;этого профиля нет чата или группы. Мы&nbsp;добавим
                ссылку, как только они появятся.
              </p>
            </div>
          </div>
        </section>
        <BaseButton
          theme="primary"
          @click="$emit('close')"
          >Закрыть</BaseButton
        >
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
/**
 * Модалка с инфой о том как найти команду
 */
import CopyInput from "@/components/CopyInput";
import { teamInviteURL } from "@/utils/urls";

export default {
  name: "TeamFindmodal",
  components: {
    CopyInput,
  },
  props: {
    profileId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isHighSchool() {
      return this.$store.getters["participant/isHighSchool"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    team() {
      return this.$store.state.participant.teams[this.profileId];
    },
    inviteLink() {
      const { team, isTeamOwner } = this;
      if (!isTeamOwner || !team?.invite_code) return;
      return teamInviteURL(team.invite_code, this.profileId, team.id);
    },
    isTeamOwner() {
      const { team, user } = this;
      if (!team) return;
      return team.owner === user.talent_id;
    },
    profile() {
      return this.$store.state.profile.profiles[this.profileId];
    },
    chatLinks() {
      const { profile } = this;
      if (!profile) return [];
      return profile.chats?.filter((n) => n.teamlink);
    },
    participantTrack() {
      const tracks = this.$store.state.tracks;
      const id =
        this.$store.getters["participant/teamProfileTracks"][this.profileId] ||
        this.$store.state.participant.track.id;

      const track = tracks[id];
      if (track) return track.title;
      return undefined;
    },
  },
};
</script>

<style></style>
